<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-right>
      <DefaultButton
        v-if="$store.state.userProfile.type === 'provider'"
        :labelText="'Edit Profile'"
        @click="handleEditProfile"
      />
    </template>
    <profile
      ref="profile"
      :isEditProfile="true"
      @click-update="handleEditProfile"
      :isEditing="false"
    ></profile>
    <dashboard-profile-modal>
      <candidate-profile-create
        v-if="edit === 'candidate'"
        @close-modal="closeProfileModal"
        @update-complete="updateProfile"
        :profile="profile"
      />
      <company-profile-create
        v-if="edit && edit !== 'candidate'"
        @close-modal="closeProfileModal"
        @update-complete="updateProfile"
        :profile="profile"
      />
    </dashboard-profile-modal>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import DashboardProfileModal from "@/components/Profile/DashboardProfileModal"
import CandidateProfileCreate from "@/components/Profile/Candidate/CandidateProfileCreate"
import CompanyProfileCreate from "@/components/Profile/Company/CompanyProfileCreate"
import DefaultButton from "@/components/DefaultButton"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    DashboardProfileModal,
    CandidateProfileCreate,
    CompanyProfileCreate,
    DefaultButton,
  },
  computed: {
    profile() {
      return this.$store.state.userProfile
    },
    profileType() {
      return this.$store.state.profileType
    },
  },
  data() {
    return {
      edit: null,
    }
  },
  methods: {
    handleEditProfile() {
      this.$bvModal.show("dashboard-profile-modal")
      this.edit = this.profileType
    },
    closeProfileModal() {
      this.$bvModal.hide("dashboard-profile-modal")
      this.edit = null
    },
    updateProfile() {
      this.$refs.profile.fetchProfile()
    },
  },
}
</script>

<style scoped lang="scss">
.edit-profile-btn {
  &:hover::after {
    border-bottom: none;
  }
}
</style>
