<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Edit billing details and view past purchases
      </span></template
    >
    <form
      role="form"
      class="billing-history-form"
      method="post"
      @submit.prevent="$emit('handle-submit')"
    >
      <b-row class="justify-content-between" style="margin: 0">
        <b-col lg="8" style="padding: 0">
          <span class="purchase-heading">Payment Information</span>
        </b-col>
        <add-card-modal></add-card-modal>
      </b-row>
      <b-row>
        <div class="payment-methods-list">
          <payment-method-display
            v-for="p in paymentMethods"
            :key="p.id"
            :paymentMethod="p"
          ></payment-method-display>
        </div>

        <!---mobile-view--->
        <div class="payment-methods-mobile mobile-cards-wrapper">
          <div
            v-for="paymentMethod in paymentMethods"
            :key="paymentMethod.id"
            class="project-wrapper mobile-card"
          >
            <div class="img">
              <span v-if="logoComponent" class="card-logo"
                ><component
                  :is="logoComponent[paymentMethod.card.brand]"
                ></component
              ></span>
            </div>
            <div class="company-name">
              {{ titleCase(paymentMethod.card.brand) }}
            </div>

            <div class="dates-wrapper">
              <div class="date">
                <span class="date-header">Exp</span>
                {{ paymentMethod.card.exp_month }}/{{
                  paymentMethod.card.exp_year
                }}
              </div>
              <div class="date text-center">
                <span class="date-header">Last 4</span>
                {{ paymentMethod.card.last4 }}
              </div>
            </div>
            <div class="btn">
              <a @click="deleteCard(paymentMethod)">Delete</a>
            </div>
          </div>
        </div>
        <!---mobile-view ends--->

        <div class="row" v-if="paymentMethods.length == 0">
          <div
            v-if="loadingMethods"
            class="text-center align-items-center"
            style="margin: auto"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
        <!--b-col lg="6">
                <b-form-group label="Card Number">
                  <base-input
                    class="mb-4"
                    v-model="cardNumber"
                    placeholder="Card Number"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Expiration Date">
                  <base-input
                    class="mb-4"
                    v-model="expiration"
                    placeholder="Expiration Date"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col-->
      </b-row>
    </form>
    <div class="purchase-heading">Purchase History</div>
    <table
      class="purchase-history table table-striped table-hover activities-table jobs-table"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, index) in pastPurchases" :key="index">
          <td style="font-weight: 600">
            {{ new Date(p.created * 1000).toLocaleDateString() }}
          </td>
          <td>{{ p.metadata.product_name }}</td>
          <td>
            {{ capitalizeFirstLetter(p.payment_method_details.card.brand) }} *{{
              p.payment_method_details.card.last4
            }}
          </td>
          <td>${{ p.amount / 100 }}</td>
          <td class="action-data">
            <default-button
              @click="openReceipt(p.receipt_url)"
              labelText="View Receipt"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <!---mobile-view--->
    <div class="purchase-history-mobile mobile-cards-wrapper">
      <div
        v-for="(p, index) in pastPurchases"
        :key="index"
        class="project-wrapper mobile-card"
      >
        <div class="company-name">
          {{ p.metadata.product_name }}
        </div>
        <div class="purchase-date">
          {{ new Date(p.created * 1000).toLocaleDateString() }}
        </div>
        <div class="dates-wrapper">
          <div class="date">
            <span class="date-header">Card Info</span>
            {{ capitalizeFirstLetter(p.payment_method_details.card.brand) }} *{{
              p.payment_method_details.card.last4
            }}
          </div>
          <div class="date text-center">
            <span class="date-header">Amount</span>
            ${{ p.amount / 100 }}
          </div>
        </div>
      </div>
    </div>
    <!---mobile-view ends--->
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { titleCase } from "@/utils/converters"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import stripeMixin from "@/mixins/stripeMixin"
import addCardModal from "@/components/Payment/AddCardModal"
import PaymentMethodDisplay from "@/components/Payment/PaymentMethodDisplay.vue"
import AmexLogo from "@/components/Payment/Logos/AmexLogo"
import DinersLogo from "@/components/Payment/Logos/DinersLogo"
import DiscoverLogo from "@/components/Payment/Logos/DiscoverLogo"
import JcbLogo from "@/components/Payment/Logos/JcbLogo"
import MastercardLogo from "@/components/Payment/Logos/MastercardLogo"
import UnionpayLogo from "@/components/Payment/Logos/UnionpayLogo"
import VisaLogo from "@/components/Payment/Logos/VisaLogo"

export default {
  name: "Billing",
  mixins: [stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    addCardModal,
    PaymentMethodDisplay,
  },
  data() {
    return {
      paymentMethods: [],
      selectedPaymentMethod: null,
      fields: {
        name: this.$store.state.userProfile.name,
        address: this.$store.state.userProfile.address,
        city: this.$store.state.userProfile.city,
        state: this.$store.state.userProfile.state,
        zip: this.$store.state.userProfile.zip,
      },
      cardNumber: null,
      expiration: null,
      customer: "",
      pastPurchases: [],
      loadingData: false,
      loadingMethods: false,
      columns: ["Date", "Purchased", "Card Info", "Amount", "Actions"],
    }
  },
  async created() {
    await this.getPaymentMethods()
    await this.getCharges(this.customer)
  },
  computed: {
    logoComponent() {
      const logos = {
        amex: AmexLogo,
        diners: DinersLogo,
        discover: DiscoverLogo,
        jcb: JcbLogo,
        mastercard: MastercardLogo,
        unionpay: UnionpayLogo,
        visa: VisaLogo,
      }
      return logos
    },
  },
  methods: {
    async getPaymentMethods() {
      try {
        this.loadingMethods = true
        const response = await this.$store.dispatch("api/getPaymentMethods")
        if (response.data.errors) {
          this.logDevError(response.data?.errors?.[0])
        } else {
          const profile = this.$store.state.userProfile
          const defaultPaymentMethodId = profile?.defaultPaymentMethod
          const paymentMethods = response.data
          this.paymentMethods = paymentMethods ?? []
          const currentPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === this.currentPaymentMethod
          )
          const defaultPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
          )
          const selectedPaymentMethod =
            currentPaymentMethod ??
            defaultPaymentMethod ??
            paymentMethods[0] ??
            null
          this.selectedPaymentMethod = selectedPaymentMethod
          this.cardNumber =
            "Ending in " + this.selectedPaymentMethod?.card?.last4
          this.expiration =
            this.selectedPaymentMethod?.card?.exp_month +
            "/" +
            this.selectedPaymentMethod?.card?.exp_year
          this.customer = this.selectedPaymentMethod?.customer
        }
        this.loadingMethods = false
      } catch (e) {
        this.loadingMethods = false
        console.error(e)
      }
    },
    async getCharges() {
      try {
        this.loadingData = true
        let pastPurchases = await this.$store.dispatch("api/getPaymentHistory")
        console.log(pastPurchases.data)
        this.pastPurchases = pastPurchases.data
        this.loadingData = false
      } catch (e) {
        this.loadingData = false
        console.error(e)
      }
    },
    capitalizeFirstLetter(str) {
      if (str && typeof str === "string") {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
      return str
    },
    async deleteCard(paymentMethod) {
      const response = await this.$store.dispatch(
        "api/deletePaymentMethod",
        paymentMethod.id
      )
      console.log("response", response)
      window.location.reload()
    },
    titleCase(str) {
      return titleCase(str)
    },
    openReceipt(url) {
      window.open(url, "_blank")
    },
  },
}
</script>
<style lang="scss" scoped>
.payment-method {
  width: 100%;
  margin-top: 12px;
}
.purchase-heading {
  color: var(--Gray-900, #343946);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 48px 0 16px;
}

.billing-history-form {
  max-width: unset;
}

.payment-methods-list {
  width: 100%;
}

.purchase-history-mobile,
.payment-methods-mobile {
  display: none;
}

@media (max-width: 768px) {
  .purchase-history,
  .payment-methods-list {
    display: none;
  }

  .purchase-history-mobile,
  .payment-methods-mobile {
    display: flex;
    width: 100%;
  }
}

.purchase-date {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 24px;
}

.card-logo svg {
  width: 70px;
}
</style>
