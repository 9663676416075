<template>
  <default-modal
    :description="body"
    :title="title"
    @onConfirm="handleClick(true)"
    @onCancel="handleCancel"
    id="public-status-modal"
    :showCancel="true"
    confirmLabel="Yes"
  >
  </default-modal>
</template>

<script>
export default {
  name: "public-status-modal",
  props: {
    title: {
      type: String,
      default: "Ready to Receive Offers?",
    },
    body: {
      type: String,
      default:
        "Businesses are prepared to invest in contacting you, please only select YES below if you are prepared to receive potential job offers, schedule interviews, and make a career transition",
    },
  },
  methods: {
    handleClick(value) {
      this.$emit("update-status", value)
    },
    handleCancel() {
      this.$bvModal.hide("public-status-modal")
      window.location.reload()
    },
    handleModalHide() {
      this.$emit("modal-hide")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  & .modal-header {
    & .close span {
      font-size: 2.5rem;
    }
  }
}
</style>
