<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Set yourself up for success with notification settings
      </span></template
    >
    <div class="container-fluid" style="max-width: 80vw">
      <b-row class="notification-row my-4">
        <b-col>
          <h3 class="heading">Notification Settings</h3>
        </b-col>
        <b-col class="switch-grp"> </b-col>
      </b-row>
      <b-form-group
        label="Communication from GoFindBuild"
        label-cols-sm="6"
        label-cols-lg="6"
        content-cols-sm
        content-cols-lg="6"
        v-slot="{ ariaDescribedby }"
        class="gfb-radio-container notification-grp required"
      >
        <b-form-radio-group
          id="communication"
          class="form-group gfb-radio-container actively-hiring-switch"
          v-model="notifications.communication"
          :options="trueFalse"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="Hiring Notifications"
        label-cols-sm="6"
        label-cols-lg="6"
        content-cols-sm
        content-cols-lg="6"
        v-slot="{ ariaDescribedby }"
        class="gfb-radio-container notification-grp required"
      >
        <b-form-radio-group
          id="hiring"
          class="form-group gfb-radio-container actively-hiring-switch"
          v-model="notifications.hiring"
          :options="trueFalse"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="Project Notifications"
        v-if="$store.state.userProfile.type === 'provider'"
        label-cols-sm="6"
        label-cols-lg="6"
        content-cols-sm
        content-cols-lg="6"
        v-slot="{ ariaDescribedby }"
        class="gfb-radio-container notification-grp required"
      >
        <b-form-radio-group
          id="project"
          class="form-group gfb-radio-container actively-hiring-switch"
          v-model="notifications.project"
          :options="trueFalse"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>
      <!--b-row class="my-4">
        <b-col>
          <h4>Connection Notifications</h4>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="notifications.connection"
            switch
            id="connection"
            class="actively-hiring-switch"
          ></b-form-checkbox>
        </b-col>
      </b-row-->
      <b-row class="notification-row my-4">
        <b-col></b-col>
        <b-col class="switch-grp">
          <default-button :labelText="'Save'" @click="updateProfile" />
        </b-col>
      </b-row>
    </div>
    <toast-message ref="toastMessage" />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import options from "@/utils/options"

export default {
  components: {
    InnerLayoutActionBarWrapper,
  },
  data() {
    return {
      notifications: {
        communication:
          this.$store.state.userProfile.notifications?.communication,
        hiring: this.$store.state.userProfile.notifications?.hiring,
        project: this.$store.state.userProfile.notifications?.project,
        connection: this.$store.state.userProfile.notifications?.connection,
      },
      trueFalse: options.trueFalse,
    }
  },
  methods: {
    async updateProfile() {
      let profile = this.$store.state.userProfile
      profile.notifications = this.notifications

      try {
        await this.$store.dispatch(
          `api/${
            profile.type == "jobSeeker"
              ? "updateProfile"
              : "updateCompanyProfile"
          }`,
          {
            profileId: profile._id,
            data: { ...profile },
          }
        )

        //Show Toast success
        this.$refs.toastMessage.showToast(
          "Notification Settings Saved successfully!!",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast(
          "Cannot save notification settings !! try again !!",
          "error"
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.actively-hiring-label {
  white-space: nowrap;
}

.actively-hiring-switch,
.switch-grp {
  justify-content: flex-end;
}

.notification-row {
  align-items: center;
}

.switch-grp {
  display: flex;
}

h4.sub-heading {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

h3.heading {
  color: var(--Gray-900, #343946);
  font-size: 18px !important;
  font-weight: 600;
  text-transform: none !important;
}

.notification-row {
  margin-top: 0 !important;
}
</style>
